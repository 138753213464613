.statsText {
  font-size: 0.8rem;
  font-weight: 600;
  text-align: center;

  &:first-child {
    font-size: 1.6rem;
  }
}

// .sectionFour {
//   width: 90%;
// }

@media screen and (max-width: 767px) {
  .sectionOne {
    position: relative;
    background-color: #fff;
    // margin-top: 45vh;

    .paraText {
      margin-top: -18vh;
      text-align: center;
      max-width: 70%;
    }

    .paraLine {
      border: 2px solid transparent;
      border-image: linear-gradient(25deg, #c3ee00 0%, #91aa2b 70%);
      border-image-slice: 1;
      width: 20%;
      margin-top: 2rem;
    }

    .productImg {
      display: none;
    }

    .mobImg {
      margin: 3rem 0 1rem;
      display: block;
      min-width: 100%;
      height: 290px;
      object-fit: cover;
      filter: brightness(0.4);
    }

    .divCont {
      justify-content: center;
      align-items: center;
      text-align: justify;
    }

    .title {
      position: absolute;
      top: 59vh;
      z-index: 5;
      color: #fff;
      max-width: 260px;
      font-size: 2rem;
      justify-self: center;
      align-self: center;
      text-align: center !important;
      line-height: 1.4;
      padding: 1.2rem;
      background: rgba(25, 31, 1, 0.5);
      border: none;
      // border: 2px solid #91aa2b;

      // @media screen and (min-width) {
        
      // }
    }

    .statsDiv {
      flex-direction: column;
    }

    .statsCard {
      flex-direction: row;
      margin-bottom: 0;
      max-width: 100%;
      width: 100%;
      gap: 0.8rem;
      background: var(--primary--06);

      .statsText {
        font-size: 0.8rem;
        font-weight: 600;
        color: var(--primary--03);

        &:first-child {
          font-size: 1.6rem;
        }
      }

      .statsIcon {
        width: 70px;
      }

      &:nth-child(2) {
        width: 80%;
        background: var(--primary--09);

        .statsText {
          color: var(--primary--04);
        }
      }

      .textDiv {
        align-items: flex-start;
        color: var(--primary--04);
      }
    }
  }

  .sectionTwo {
    padding-top: 0;
    position: relative;

    .container {
      position: relative;
      top: -10vh;
    }

    .agroImg {
      min-width: 100%;
      filter: brightness(0.8);
      margin: 0 -1rem;
    }

    .introDiv {
      display: flex;
      align-items: center;
      background-color: var(--primary--08);
      border-radius: 20px;
      box-shadow: 0px 16px 35px 0px rgba(0, 0, 0, 0.05);
    }

    .imgDiv {
      // background-color: var(--primary--08);
      min-width: 35%;
      min-height: 100%;
      text-align: center;
    }

    .proImg {
      width: 90%;
    }

    .introText {
      background-color: #fff;
      padding: 0.5rem;
      border-radius: 0 20px 20px 0;
      min-height: 100%;

      p {
        font-size: 14px;
        line-height: 1.4;
        color: #3c4044;
        text-align: left;
      }

      .actionDiv {
        display: flex;
        gap: 1.8rem;
        margin: 1rem 0;

        .detailBtn {
          border: 1px solid var(--primary--05);
          color: var(--primary--05);
          padding: 0.7rem 1rem;
          border-radius: 4px;
          background-color: transparent;
        }

        .orderBtn {
          @extend .detailBtn;
          background-color: var(--primary--05);
          color: #fff;
        }
      }

      .productImgB {
        display: none;
      }
    }

    .ignoreDesk,
    .deskDiv {
      display: none;
    }

    .productTitle {
      text-align: left;
      font-family: var(--open);
      color: #212529;
      font-size: 1.33rem;
      line-height: 1.4;
    }
  }

  .sectionThree {
    text-align: justify;
    .flipDiv {
      padding-top: 0 !important;
    }

    .productImg,
    .imageDiv {
      display: none;
    }

    .productImgB {
      margin: 3rem 0 1rem;
      display: block;
      max-width: 100%;
      height: 290px;
      object-fit: cover;
      filter: brightness(0.4);
    }

    .mobImg {
      display: block;
      width: 100%;
      height: 290px;
      object-fit: contain;
      filter: brightness(0.6);
    }

    .divCont {
      justify-content: center;
      align-items: center;
      position: relative;

      .title {
        position: absolute;
        top: -33vh;
        z-index: 5;
        color: #fff;
        max-width: 225px;
        font-size: 2rem;
        justify-self: center;
        align-self: center;
        text-align: center !important;
        line-height: 1.4;
        padding: 1.2rem;
        background: rgba(25, 31, 1, 0.8);
        border: none;
      }

      .btnDiv {
        margin: 1rem 0;
      }
    }

    .statsDiv {
      flex-direction: column;
    }

    .statsCard {
      flex-direction: row;
      margin-bottom: 0;
      max-width: 100%;
      width: 100%;
      gap: 0.8rem;
      color: var(--white);

      .statsText {
        font-size: 0.8rem;
        font-weight: 600;

        &:first-child {
          font-size: 1.6rem;
        }
      }

      .statsIcon {
        width: 50px;
        color: #fff;
      }

      &:nth-of-type(1) {
        width: 75%;
        
        .statsText {
          color: var(--white);
        }
      }

      &:nth-of-type(2) {
        width: 85%;

        .statsText {
          color: var(--primary--03);
        }
      }

      &:nth-of-type(3) {
        width: 100%;

        .statsText {
          color: var(--primary--04);
        }
      }

      .textDiv {
        align-items: flex-start;
      }
    }
  }

  .sectionFour {
    position: relative;

    .deskImg {
      display: none;
    }

    img.mobImg {
      margin: 3rem 0 1rem;
      display: block;
      width: 100%;
      height: 290px;
      object-fit: cover;
      filter: brightness(0.2);
    }

    .title {
      position: absolute;
      top: 16vh;
      z-index: 5;
      // margin-top: 0;
      color: #fff;
      font-size: 2rem;
      justify-self: center;
      align-self: center;
      text-align: center !important;
      line-height: 1.4;
      width: 100%;
      padding: 1.2rem;
      background: rgba(25, 31, 1, 0.8);
      border: none;
    }
  }

  .sectionFive {
    display: grid;

    .styleDiv {
      flex-direction: row;
      text-align: justify;
      padding: 0 1rem 1rem;

      .mobImgB {
        max-width: 50%;
      }
    }

    .deskImg,
    .deskText {
      display: none;
    }
  }

  .spatialImg {
    padding-left: 0.4rem;
  }

  .droneSection {
    padding: 0 1.5rem 3rem;

    h4 {
      width: 100%;
    }

    .droneDiv {
      flex-direction: column;
      border-bottom: 1px solid rgba(145, 170, 43, 0.6);
      padding-bottom: 3rem;
      width: 100%;

      .droneInfo {
        width: 100%;
      }

      .drone {
        display: flex;
        max-width: 300px;
        width: 80%;
        align-self: center;
        margin-bottom: 1rem;
      }

     button {
      font-size: 1rem;
     }
    }

    p {
      line-height: 1.4;
      text-align: center;
    }

    .droneBtn {
      display: none;
    }

    .statsContainer {
      flex-direction: column;

      .statsCard {
        flex-direction: row;
        margin-bottom: 0;
        max-width: 100%;
        width: 100%;
        gap: 0.8rem;
        color: var(--white);
  
        .statsText {
          font-size: 0.8rem;
          font-weight: 600;

          &:first-child {
            font-size: 1.6rem;
          }
        }
  
        .statsIcon {
          width: 50px;
          color: #fff;
        }
  
        &:nth-of-type(1) {
          width: 100%;
          
          .statsText {
            color: var(--white);
          }
        }
  
        &:nth-of-type(2) {
          width: 85%;
  
          .statsText {
            color: var(--primary--03);
          }
        }
  
        &:nth-of-type(3) {
          width: 60%;
  
          .statsText {
            color: var(--primary--04);
          }
        }
      }

      button {
        margin-top: 3rem;
        font-size: 1rem;
      }
    }

  }
}

@media screen and (min-width: 768px) {
  .mobImg,
  .mobImgB,
  .ivrRBtn,
  .agroImg,
  .imgDiv,
  .productImgB,
  .mobText {
    display: none !important;
  }
}