.featureContainer {
  position: relative;
  top: -10vh;
  width: 100%;
  background-color: #fff;

  @media screen and (min-width: 768px) {
    background-color: var(--primary--08);
    top: -100vh;
    padding-top: 40vh;
  }

  @media screen and (min-width: 800px) {
    padding-top: 37vh;
  }

  @media screen and (min-width: 1024px) {
    top: -115vh;
    padding-top: 45vh;
  }

  @media screen and (min-width: 1280px) {
    top: -100vh;
  }
}

.serviceContainer {
  @extend .featureContainer;
  top: -2vh;
  margin-top: 10rem;

  @media screen and (min-width: 768px) {
    background-color: #fff;
    padding-top: 0;
    margin-top: -65vh;
    top: -10vh;
  }

  @media screen and (min-width: 800px) {
    padding-top: 3vh;
    top: 0;
  }

  @media screen and (min-width: 1024px) {
    margin-top: -80vh;
  }

  @media screen and (min-width: 1280px) {
    margin-top: -60vh;
  }

  .aboutTitle {
    @media screen and (min-width: 768px) {
      text-align: left;
      max-width: max-content;
      color: var(--primary--04);
      font-weight: 400;
    }
  }
}

.aboutTitle {
  font-family: var(--tiempos-fine);
  max-width: 306px;
  width: 65%;
  text-align: center;
  margin: 0 auto;
  font-size: 1.5rem;
  color: #212529;

  @media screen and (min-width: 768px) {
    text-align: left;
    max-width: max-content;
    color: var(--primary--04);
    font-weight: 400;
  }
}

.container {
  background-color: var(--white);
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
  margin: 2rem auto;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 768px) {
    background-color: var(--primary--08);
    flex-direction: row;
    padding: 0 15% 3%;
    margin-bottom: 4rem;
  } 
}

.containerB {
  @extend .container;
  background-color: #fff;

  @media screen and (min-width: 1024px) {
    margin-top: 3rem;
  }
}

// Styles for the product section
.productWrapper {
  display: block;
  background-color: var(--primary--08);
  border-radius: 10px;
  width: 80%;

  @media (min-width: 538px) {
    max-width: 400px;
    border-radius: 30px;
  }

  @media screen and (min-width: 768px) {
    box-shadow: 0px 16px 25px rgba(0, 0, 0, 0.1);
    max-width: 100%;
  }
}

.productWrapperB {
  @extend .productWrapper;
}

.featuresDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;

  @media screen and (min-width: 768px) {
    width: 100%;
    padding: 4rem 0 0;
    background-color: #fff;
    border-radius: 30px;
    height: 260px;
  }
}

.featuresDivVar {
  @extend .featuresDiv;
  padding: 0 0 1rem;
  background-color: #fff;

  @media screen and (min-width: 768px) {
    padding: 1rem 0 0;
    height: 260px;
    gap: 0;

    .featBody {
      background-color: var(--primary--05);
      color: #fff;
      border-radius: 0 0 30px 30px;
      padding: 1rem;
    }
  }
}

.featureImg {
  max-width: 80%;
  width: 50%;

  @media screen and (min-width: 768px) {
    max-width: 100%;
    width: 65%;
  }
}

.featureImgVar {
  border-radius: 10px 10px 0 0;
  max-width: 100%;
  width: 100%;
  height: 200px;
  object-fit: cover;

  @media screen and (min-width: 768px) {
    border-radius: 30px 30px 0 0;

  }
}

.featureHeader {
  flex-direction: column;
  height: inherit;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  color: var(--primary--04);

  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: row;
    width: 70%;
    gap: 0.5rem;
    margin: 0 auto;
  }
}

.featBody {
  @media screen and (min-width: 768px) {
    background-color: var(--primary--04);
    width: 100%;
    height: 150px;
    background-color: var(--primary--05);
    color: #fff;
    border-radius: 0 0 30px 30px;
    padding: 1rem;
  }
}

.featureTitle {
  text-align: center;
  text-transform: capitalize;
  font-weight: var(--wgt--400);
  color: #181b1e;
  // font-size: 0.6rem;

  @media screen and (min-width: 768px) {
    color: #fff;
    text-align: left;
    text-transform: uppercase;
    font-weight: var(--wgt--700);
    font-size: 50%;
    max-width: 80%;
    min-height: 76px;
  }

  @media screen and (min-width: 956px) {
    font-size: 70%;
  }
}

.aboutBorder {
  width: 10%;
  border: 1px solid var(--primary--07);
  margin: 0.5rem auto 0;

  @media screen and (min-width: 768px) {
    display: none;
  }
}

.aboutBorderBig {
  @extend .aboutBorder;
  width: 40%;

  @media screen and (min-width: 768px) {
    max-width: 100%;
    width: 100%;
    display: flex;
  }
}
