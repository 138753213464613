.main--container {
  position: relative;
  height: 100%;
  animation: slide-in 0.5s forwards;
}

.header--container {
  width: 100%;
  position: fixed;
  justify-content: var(--space-between);
  padding: 1vw 3vw;
  z-index: 500000;
  left: 50%;
  top: 5.1vh;
  transform: translate(-50%, -50%);
  height: 80px;
  background: #0d1703;
  align-content: center;
}

.nav--container {
  position: fixed;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(13, 23, 3, 0.6);
  backdrop-filter: blur(5px);
}

.logo {
  align-self: center;
  max-width: 75%;
  display: block;
}

.nav--item {
  color: var(--primary--03);
  font-size: 1rem;
  padding: 1rem;
  width: 100%;
  position: relative;
  font-weight: var(--wgt--600);
  transition: all 0.3s ease-in-out;
}

.nav--item:last-of-type {
  margin-bottom: 2rem;
}

.nav--item--container {
  width: 100%;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--primary--03);
}

.nav--item::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  opacity: 0;
  padding: 1rem 2rem;
  height: 0;
  color: var(--primary--04);
  background-color: rgba(210, 224, 143, 0.5); /* Color of spreading effect */
  border-radius: 50%;
  transition: width 0.3s ease-out, height 0.3s ease-out, opacity 0.5s ease-out;
  pointer-events: none;
}

.nav--item:active::after {
  width: 10%;
  height: 55%;
  color: #fff;
  opacity: 1;
}

/* Animation Effects */
@media screen and (max-width: 767px) {
  .slide-enter {
    transform: translateX(100%);
  }

  .slide-enter-active {
    transform: translateX(0);
    transition: transform 300ms ease-in;
  }

  .slide-exit {
    transform: translateX(0);
  }

  .slide-exit-active {
    transform: translateX(-100%);
    transition: transform 300ms ease-in;
  }
}

/* End */

.exit {
  position: absolute;
  z-index: 20;
  left: 90vw;
}

.hamburger {
  color: var(--primary--07);
  align-self: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.hamburger:hover {
  transform: scale(1.1);
}

.hamburger:active {
  transform: scale(0.9);
}

.nav--list {
  position: absolute;
  flex-direction: column;
  top: 10vh;
  overflow: scroll;
  left: 50%;
  width: 50%;
  height: 100vh;
  align-items: start;
  background-color: #fff;
  z-index: 1000000;
  padding: 10%;
  gap: 1rem;
  transition: all 0.9s ease-in-out;
  animation: slide-in 1s forwards;
}

.success {
  color: var(--alt-white);
  margin-top: 1.5rem;
  text-align: center;
  font-size: 0.9rem;
  bottom: -30px;
  animation: slide-in 0.5s forwards;
}

@keyframes slide-in {
  0% {
    bottom: -40px;
  }

  100% {
    bottom: 0;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.slide-in {
  animation: slideIn 0.5s forwards;
}

.hide--desk {
  display: inline-flex;
}

.hide--mob {
  display: none;
}

/* -------- <768px------ */
@media only screen and (min-width: 768px) {
  .header--container {
    width: 97%;
    position: fixed;
    justify-content: center;
    padding: 0 1rem;
    top: 5vh;
    border-radius: 20px;
  }

  .logo {
    max-width: 100%;
  }

  .nav--container {
    position: static;
    width: 100%;
    height: inherit;
    background-color: unset;
    backdrop-filter: unset;
  }

  .nav--list {
    position: static;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    width: 100%;
    background-color: transparent;
    border-radius: 0;
    gap: 24px;
    padding: 0;
    overflow: hidden;
  }

  .nav--item {
    color: var(--white);
    font-size: 18px;
    display: block;
    width: max-content;
    padding: 1rem 0;
    white-space: nowrap;
  }

  .nav--item:last-of-type {
    margin-bottom: 0;
  }

  .nav--item:hover {
    background-color: var(--primary--01);
    border-radius: 5px;
    padding: 8px;
    border-bottom: 1px solid var(--primary--01);
  }

  .nav--item--active {
    opacity: 0.5;
  }

  .hide--desk {
    display: none;
  }

  .hide--mob {
    display: flex;
  }

  .nav--item--container {
    position: static;
    width: max-content;
    padding-bottom: 0;
    border-bottom: none;
  }

  .nav--item::after {
    content: none;
  }
}

@media screen and (min-width: 956px) {
  .header--container {
    top: 8vh;
    width: 85%;
  }
}
