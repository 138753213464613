.abtDiv {
  position: relative;

  @media screen and (min-width: 768px) {
    padding-top: 20vh;
    width: 80%;
    background: transparent;
    // background-color: #fff;
  }
}

.abtPara {
  margin-top: -18vh;
  text-align: center;
  max-width: 70%;

  @media screen and (min-width: 768px) {
    text-align: left;
    padding-left: 1rem;
    border-left: 5px solid transparent;
    border-image: linear-gradient(25deg, #c3ee00 0%, #91aa2b 70%);
    border-image-slice: 1;
    width: 100%;
    align-self: flex-start;
    max-width: 100%;
  }
}

.paraLine {
  border: 2px solid transparent;
  border-image: linear-gradient(25deg, #c3ee00 0%, #91aa2b 70%);
  border-image-slice: 1;
  width: 20%;
  margin-top: 2rem;

  @media screen and (min-width: 768px) {
    display: none;
  }
}

.flipDiv {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  padding: 0 1rem;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    gap: 1.5rem;
    margin-top: 5rem !important;
    padding-bottom: 2rem;
  }
}

.flipDivB {
  @extend .flipDiv;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    gap: 1.5rem;
    padding-bottom: 2rem;
    width: 80%;
  }
}

.revealImg {
  display: flex;
  width: 100%;

  @media screen and (min-width: 768px) {
    min-width: 40%;
    align-self: stretch;
  }
}

.riceImg {
  margin: 3rem 0 1rem;
  display: block;
  min-width: 100%;
  height: 290px;
  object-fit: cover;
  filter: brightness(0.4);

  @media screen and (min-width: 768px) {
    box-shadow: 10px 3px 45px rgba(0, 0, 0, 0.2);
    border-radius: 120px 120px 120px 0px;
    height: 95%;
    margin-bottom: 0;
  }
}

.riceImgB {
  @extend .riceImg;
  margin-top: -10vh;
  height: 260px;

  @media screen and (min-width: 768px) {
    margin-top: 0;
    height: 100%;
  }
}

.driveTitle {
  position: absolute;
  top: -37vh;
  z-index: 5;
  color: #fff;
  max-width: 30%;
  font-size: 2rem;
  justify-self: center;
  align-self: center;
  text-align: center;
  line-height: 1.4;
  padding: 1.2rem;
  background: rgba(25, 31, 1, 0.5);
  border: 2px solid #91aa2b;

  @media screen and (min-width: 768px) {
    position: static;
    justify-self: flex-start;
    align-self: flex-start;
    color: var(--primary--03);
    max-width: 60%;
    text-align: left;
    padding: 0;
    background: none;
    border: none;
    line-height: normal;
  }
}

.driveTitleB {
  @extend .driveTitle;
  top: -30vh;
  max-width: 50%;
  text-align: center;
}

.teamTop {
  @extend .driveTitleB;
  top: 11vh;
  border: none;
  max-width: 100%;

  @media screen and (min-width: 768px) {
    position: static;
    margin: 3rem auto 0;

    h3 {
      color: var(--primary--03);
    }
  }
}

.bgContainer {
  @media screen and (min-width: 768px) {
    background-image: url('../../../assets/backgrounds/globe.webp');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: 50% 20%;
  }
}
