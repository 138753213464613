@media screen and (max-width: 767px) {
  .servicesSection {
    width: 100%;
    position: relative;
    padding: 0 1rem;

    .deskImg {
      display: none;
    }
  
    li,
    p {
      text-align: justify;
      width: 95%;
      color: #3c4044;
      // align-self: stretch;
    }

    .title {
      position: static;
      text-align: center;
      border: none;
      padding: 0;
      font-weight: 600;
      color: #212529;
      font-size: 2rem;
    }

    .agroImg {
      display: none;
    }
  
    .reverseDiv {
      display: flex;
      width: 100%;

      button {
        margin-top: -1rem;
        font-size: 1rem;
      }

      flex-direction: column-reverse;

      .listDiv {
        margin: 0;
        line-height: 1.5;
        width: 100%;
        justify-content: center;
        align-items: center;  
      }

      .imgDiv {
        position: static;
        width: 100%;
        padding: 0;
        gap: 0.2rem;
        margin: 2rem 0 3rem;
        background-color: transparent;
        box-shadow: none;

        .imgA {
          border-top-left-radius: 60px;
        }

        .imgA {
          object-fit: cover;
          height: 185px;
        }

        img {
          display: block;
          max-width: 100%;
          width: 35%;
          height: 185px;
          object-fit: cover;
        }
      }
    }

      .sectionCultivation {
        width: 100%;

        .contDiv {
          width: 100%;

          .flexDiv {
            width: 100%;
  
            .groupImg {
              position: static;
              padding: 0;
              background-color: transparent;
              box-shadow: none;
              display: flex;
              margin: 1.5rem auto 0.8rem;
              gap: 0.4rem;
              width: 100%;
              max-width: 80%;
  
              img {
                max-width: 50%;
  
                &:nth-of-type(1) {
                  border-bottom-left-radius: 80px;
                }
  
                &:nth-of-type(2) {
                  border-top-right-radius: 120px;
                }
              }
            }
          }

          p {
            width: 100%;

            &:nth-of-type(2) {
              margin-top: 1rem;
            }
          }
        }

        .statsContainer {
          flex-direction: column;
          padding-top: 3rem;
          width: 100%;
          
          .statsCard {
            flex-direction: row;
            margin-bottom: 0;
            max-width: 100%;
            width: 100%;
            gap: 0.8rem;
            color: var(--white);
      
            .statsText {
              font-size: 0.8rem;
              font-weight: 600;
      
              &:first-child {
                font-size: 1.6rem;
              }
            }
      
            .statsIcon {
              width: 50px;
              color: #fff;
            }
      
            &:nth-of-type(1) {
              width: 75%;
              
              .statsText {
                color: var(--white);
                text-align: left;
              }
            }
      
            &:nth-of-type(2) {
              width: 85%;
      
              .statsText {
                color: var(--primary--03);
              }
            }
      
            &:nth-of-type(3) {
              width: 100%;
      
              .statsText {
                color: var(--primary--04);
              }
            }
      
            .textDiv {
              align-items: flex-start;
            }
          }
        }

        .operationDiv {
          width: 100%;
          box-shadow: none;
          padding: 0 0 3rem;
        }
      }
    }
             
    .researchDiv {
      width: 100%;

      .intro,
      .intoT {
        width: 100%;
      }

      .adjServices {
        width: 100%;
      }

      .researchImg {
        width: 100%;
        height: 200px;
        object-fit: cover;
        border-top-left-radius: 60px;
        margin: 20px auto 10px;
      }

      p {
        width: 100%;
        margin-bottom: 1rem;
      }

      .listDiv {
        margin: 0 auto !important;
      }
    }
  
}

@media screen and (min-width: 1270px) {
  .servicesSection {
    .reverseDiv {
      .imgDiv {
        top: -20vh !important;
        // display: none;
      }
    }

    .listDiv {
      margin: 30vh auto 0 !important;; 
    }
  }
}

@media screen and (min-width: 1064px) {
  .reverseDiv {
    .imgDiv {
      top: -32vh;
    }

    .listDiv {
      margin: 25vh auto 0; 
    }
  }
}

@media screen and (min-width: 968px) {
  .reverseDiv {
    .imgDiv {
      top: -35vh;
    }

    .listDiv {
      margin: 20vh auto 0; 
    }
  }
}


@media screen and (min-width: 800px) {
  .reverseDiv {
    .imgDiv {
      top: -22vh;
    }

    .listDiv {
      margin: 28vh auto 0; 
    }
  }
}

@media screen and (min-width: 768px) {
  .groupImg,
  .statsContainer {
    display: none;
  }

  .reverseDiv {
    button {
      display: none;
    }
  }

  // button {
  //   display: block;
  // }

  .reverseDiv {
    display: flex;
    width: 80%;

    .listDiv {
      margin: 25vh auto 0;
      width: 80%;
      justify-content: center;
      align-items: center;  
    }

    .imgDiv {
      position: absolute;
      top: -25vh;
      left: 35vh;
      width: 70%;
      max-width: max-content;
      padding: 1rem 2rem;
      gap: 0.2rem;
      margin: 2rem 0 3rem;
      background-color: #fff;
      box-shadow: set;

      .imgA {
        border-top-left-radius: unset;
        object-fit: cover;
      }

      .imgC {
        border-bottom-right-radius: 80px;
      }
      
      img {
        display: block;
        width: 35%;
        object-fit: cover;
        height: 220px;
      }
    }
  }

  h4 {
    text-align: left;
  }
}
