.sponsorsSection {
  top: -10vh;

  li {
    display: flex;
    height: 150px;
  }

  #root > div > section.sponsors_sponsorsSection__HnQk\+ > div.react-multi-carousel-track.sc-bdvumH.gwApMQ.undefined.d-flex.a-i-c > ul {
    gap: 3rem;
    justify-content: center;
  }

  @media screen and (min-width: 768px) {
    top: -5vh;
  } 

  @media screen and (min-width: 1024px) {
    top: 0;
    margin-bottom: 5rem;
  }
}

.sponsorsDiv {
  flex-direction: column;
  height: inherit;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 3rem auto 2rem;
  color: var(--primary--04);

  @media screen and (min-width: 768px) {
    flex-direction: row !important;
    display: flex;
    width: 80%;
    gap: 1rem;
  }
}

.title {
  font-family: var(--tiempos-fine);
  max-width: 306px;
  width: 65%;
  text-align: center;
  margin: 0 auto;
  font-size: 2rem;
  color: #212529;

  @media screen and (min-width: 768px) {
    text-align: left;
    max-width: max-content;
    color: var(--primary--04);
    font-weight: 400;
    font-size: 1.5rem;
    margin: 0;
  }
}

.hrLine {
  width: 10%;
  border: 1px solid var(--primary--07);
  margin: 0.5rem auto 0;

  @media screen and (min-width: 768px) {
    max-width: 100%;
    width: 100%;
    // display: flex;
  }
}
