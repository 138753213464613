.video--container {
  position: relative;
  background: url('../../../assets/backgrounds/vid_placeholder.webp'), rgba(14, 16, 4, 0.1);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 1000px;
}

.droner {
  max-width: 100%;
  width: 95%;
  z-index: 1;
}

.video--overlay {
  position: relative;
  margin: 25vh auto 15vh;
  text-align: center;
  gap: 1rem;
  width: 80%;
}

.learn--more {
  top: -15vh;
  background: #91aa2b;
  color: #f2f7de;
  font-weight: var(--wgt--600);
  width: 35%;
  height: 3rem;
  font-size: 14px;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.learn--more:hover {
  background: #f2f7de;
  color: #91aa2b;
}

.learn--more:active {
  opacity: 0.8;
}

.home--container {
  position: relative;
  width: 100%;
}

.none--mobile {
  display: none;
}

.hero .hero--title {
  font-size: 210%;
  max-width: 399px;
  color: var(--white);
  font-weight: var(--wgt--300);
  line-height: 1.2;
  text-shadow: 0 12px 12px #000;
  margin: 0 0 2rem 0;
  letter-spacing: 1.9px;
}

.hero--bold {
  font-weight: var(--wgt--700);
}

.hero--text {
  top: -9vh;
  background: rgba(92, 102, 53, 0.3);
  background-blend-mode: multiply;
  font-weight: var(--wgt--400);
  line-height: 1.5;
  padding: 3rem 2rem;
  border-radius: 20px;
  color: var(--white);
  margin-bottom: 1rem;
  max-width: 85%;
}

@media only screen and (min-width: 768px) {
  .none--mobile {
    display: block;
  }

  .video--container {
    background: none;
  }

  .media,
  .player,
  #player,
  .vp {
    position: absolute;
    object-fit: cover;
    top: 0;
    left: 0;
    width: 100%;
    height: 1500px;
  }

  div.video--overlay {
    justify-content: start;
    align-items: flex-start;
    top: 0;
    margin-top: 10vh;
    width: 75%;
    z-index: 1500;
  }

  .video--container::after {
    content: "";
    position: absolute;
    background: rgba(14, 16, 4, 0.8);
    top: 0;
    left: 0;
    height: 1500px;
    width: 100%;
  }

  .hero .hero--title {
    font-size: 4vw;
    max-width: 800px !important;
    margin-top: 4rem;
    width: 100%;
    font-family: 'Open Sans', sans-serif;
    font-weight: var(--wgt--400);
    letter-spacing: 1.8px;
  }

  .hero .hero--text {
    line-height: 1.6;
    margin: 4rem 0 5rem;
    background: none;
    padding: 0;
    border-radius: 0;
    max-width: 70%;
  }

  .text--left {
    text-align: left;
  }

  .droner {
    position: absolute;
    width: 50%;
    left: 65%;
    top: 25vh;
  }

  .learn--more {
    font-size: 1.5rem;
    padding: 2rem 1.5rem;
  }

  .learn--more:hover {
    background: #f2f7de;
    color: rgba(149, 158, 118, 0.8);
  }

  .learn--more:active {
    opacity: 0.8;
  }
}

@media screen and (min-width: 800px) {
  .droner {
    width: 45%;
    left: 63%;
    top: 35%;
  }

  .hero .hero--text {
    margin: 5rem 0 6rem;
  }
}

@media screen and (min-width: 820px) {
  .droner {
    width: 50%;
    left: 60%;
    top: 30vh;
  }
}

@media screen and (min-width: 956px) {
  div.video--overlay {
    top: 8vh;
  }

  .hero .hero--text {
    margin: 4rem 0;
    max-width: 600px;
  }

  .droner {
    width: 45%;
    left: 63%;
    top: 32vh;
  }
}

@media screen and (min-width: 1024px) {
  .video--container::after {
    background-position: 96% 50%;
    background-size: 38%;
    height: 1200px;
  }

  @media screen and (min-width: 1200px) {
    .hero .hero--title {
      max-width: 70% !important;
    }

    .droner {
      width: 40%;
      left: 67%;
      top: 10vh;
    }
  }
}
