/* What Motivates Us */
.motivation {
  position: relative;
  top: 0;
  left: 0;
  z-index: 6000;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.hero--bg--img {
  position: absolute;
  top: -15vh;
  left: 0;
  width: 100%;
  object-fit: cover;
  z-index: 100;
}

.hero--text.black {
  color: #000;
}

.sub--hero {
  position: relative;
  z-index: 1000;
}

.sub--hero--img {
  position: relative;
  top: -18vh;
  left: 0;
  width: 40%;
  object-fit: contain;
}

.sub--hero--div {
  top: 0;
  margin-top: 10%;
  background-image: url('../../../assets/backgrounds/globe.webp');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 5% 8vh;
  z-index: 40000;
}

.gallery {
  position: relative;
  top: 15vh;
  width: var(--w--90);
  grid-template-columns: repeat(6, 1fr);
  margin: 0 auto;
  padding: 2rem 0;
  justify-content: center;
  gap: 0.2rem;
}

.image--container {
  position: relative;
  display: inline-block;
}

.image--overlay--main {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  height: 100%;
  background: rgba(69, 82, 20, 0.6);
}

.gallery--item {
  height: 100%;
}

.green-title-text {
  font-size: var(--size--72);
  color: var(--primary--03);
  font-weight: var(--wgt--400);
}

.border-image {
  border-left: 5px solid transparent;
  border-image: linear-gradient(25deg, #c3ee00 0%, #91aa2b 70%);
  border-image-slice: 1;
  width: 100%;
  padding-left: 1rem;
}

.max--text-1 {
  width: 70%;
}

.main--title {
  margin: 4rem 0 1rem 0;
}

.one {
  max-width: 65%;
}

.dark-text {
  font-weight: 600;
}

.hero--text {
  max-width: 100%;
}

.climate-art {
  position: absolute;
  top: 102%;
  width: 80%;
  background: #fff;
  margin: 0 auto;
  padding: 2rem 1.5%;
  align-items: center;
  justify-content: center;
}

.climate--title {
  max-width: 100%;
  text-align: center;
  font-size: var(--size--72);
  margin-bottom: 1rem;
}

.sub-text {
  font-size: 13px;
  line-height: 1.5;
}

.motive {
  height: 300px;
}

/* ********************** */

.flex {
  display: flex;
}

.column {
  flex-direction: column;
}

/* Featured Products */
.product {
  background-color: var(--primary--08);
  padding-top: 18rem;
}

.feature--div {
  position: relative;
  top: -20vh;
  align-items: center;
  justify-content: center;
  width: var(--w--100);
  padding: 5vh 0 65px 0;
  margin-bottom: 2rem;
}

.feature--div.services {
  padding-top: 0;
}

/* .feature--container {
  flex-direction: column;
  margin: 0 auto;
  gap: 1rem;
  width: var(--w--80);
  align-items: center;
  justify-content: center;
  height: auto;
  padding-top: 2rem;
} */

.feature--img {
  max-width: 100%;
  object-fit: cover;
}

div.feature--container.services.d-flex img.feature--img {
  width: 100%;
  border-radius: 30px 30px 0 0;
}

div.feature--container.product.d-flex img.feature--img {
  height: 240px;
  margin: 1rem 0;
}

.product--link {
  height: 100%;
  gap: 2rem;
  color: var(--alt-white);
  text-align: center;
  padding: 0.5rem;
  transition: all 0.3s ease;
}

.feature--pro {
  align-items: center;
  box-shadow: 0 16px 25px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  cursor: pointer;
}

.product--link:hover {
  transform: scale(1.05);
  opacity: 0.8;
}

.feature--body {
  width: 100%;
  height: 160px;
  background-color: var(--primary--06);
  border-radius: 0 0 30px 30px;
  padding: 1rem 2.5rem;
}

.feature--div.product.flex.column a:nth-child(odd) div div {
  background-color: var(--primary--05);
  color: #fff;
}

.feature--div.product.flex.column a:nth-child(even) div div {
  background-color: var(--primary--06);
  color: var(--primary--03);
}

.feature--div.services.flex.column a:nth-child(even) div div {
  background-color: var(--primary--05);
  color: #fff;
}

.feature--div.services.flex.column a:nth-child(odd) div div {
  background-color: var(--primary--06);
  color: var(--primary--03);
}

.card-title {
  text-transform: uppercase;
  font-weight: var(--wgt--700);
  font-size: var(--size--56);
  text-align: left;
}

section:nth-child(4) {
  top: -30vh;
}

hr.hr--theme {
  margin-top: 1rem;
  border: 1px solid var(--primary--04);
}

.sub--theme {
  font-size: var(--size--52);
  font-weight: var(--wgt--400);
}

/* ************** */

/* Funnel Section */
.funnel--page--container {
  position: absolute;
  width: var(--w--70);
  top: 60%;
  font-family: 'Inter', sans-serif, 'Open Sans', 'Helvetica Neue';
  background: var(--white);
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 1% 3%;
  box-shadow: 0 8px 16px rgba(171, 190, 209, 0.4);
}

.funnel--title {
  font-weight: var(--wgt--700);
  color: #464646;
  max-width: 50%;
  font-size: 1rem;
  text-align: center;
  padding: 1rem 0.5rem 0;
  line-height: 24px;
}

.funnel--description {
  font-weight: 500;
  color: #707070;
  font-size: 0.6rem;
}

.funnel--form {
  width: 80%;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem;
}

.funnel--input {
  width: 100%;
  box-shadow: 0 2px 12px rgba(171, 190, 209, 0.3);
  border-radius: 8px;
  border: 1px solid #b1b2b4;
  padding: 4px 4px 4px 12px;
  font-weight: 600;
  font-size: 14px;
  margin-top: 0.5rem;
}

::placeholder {
  color: #b1b2b4;
  font-size: 10px;
}

.funnel--btn {
  width: max-content;
  margin-top: 0;
  font-size: 0.8rem;
  border-radius: 8px;
  position: absolute;
  right: 1%;
  top: 42%;
  transform: translateY(-50%);
  padding: 4px 10px;
}

/* ************* */

/* Sponsors */
#root > div > section.sponsors_sponsorsSection__HnQk\+ > div.react-multi-carousel-list.sc-bdvumH.cGTpVE.undefined.d-flex.a-i-c > ul > li {
  align-self: center;
  height: 125px;
}

div.react-multi-carousel-list {
  width: 80%;
  margin: 3rem auto 0;
  gap: 2rem;
  height: max-content;
}

img.sponsor--logo {
  width: 50%;
  align-self: center !important;
}

.sponsors--page {
  top: -15vh;
}

/* ************* */

/* Footer Page */
.footer--container {
  width: 100%;
  position: relative;
  grid-template-columns: 1fr;
  padding: 3%;
  background-color: var(--primary--02);
}

.privacy {
  gap: 0.8rem;
  margin-top: 1rem;
  font-size: 0.8rem;
}

.privacy--text {
  color: #91aa2b;
}

.privacy--divider {
  justify-content: space-between;
  gap: 1rem;
}

.privacy--link {
  color: #fff;
}

.privacy--link:first-of-type {
  border-right: 2px solid #91aa2b;
  padding-right: 1rem;
}

.pro #contact-us {
  top: 6vh;
}

.footer__top {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}

.form {
  margin: 4% 0 2%;
  width: 100%;
  border: 1px solid #91aa2b;
  border-radius: 10px;
  padding: 0.5rem;
  gap: 0.5rem;
  height: max-content;
}

.text-input {
  height: 20px;
}

.contact__form--input {
  border: 1px solid #455214;
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  width: 100%;
  height: 3rem;
  padding: 1.2rem;
}

.contact__form--input::placeholder {
  color: #455214;
  font-size: 1rem;
}

textarea.contact__form--input {
  height: 70px;
  overflow: auto;
  margin: 0.5rem 0;
}

.contact__form--btn {
  width: max-content;
  padding: 3% 6%;
  border: none;
  background: #91aa2b;
  border-radius: 5px;
  color: #f2f7de;
  font-weight: 600;
  font-size: 2vh;
}

.footer__links {
  gap: 0.5rem;
  max-width: 300px;
  color: #fff;
  font-size: 14px;
}

.footer__links--top {
  gap: 0.5rem;
}

.footer--icon {
  width: 20px;
  height: 20px;
}

.footer--logo {
  margin: 3rem 0 1rem;
  max-width: 300px;
  width: 80%;
}

.socials {
  margin-top: 1.5rem;
}

.linkedin {
  font-size: 2rem;
  color: var(--primary--01);
  margin: 1rem auto;
  display: block;
}

/* Projects */

.project--container {
  padding-top: 3rem;
  width: 90% !important;
  align-items: stretch;
  grid-template-columns: 1fr;
  gap: 1rem;
  opacity: 0;
  transition: opacity 1s ease;
}

.project--container.show {
  opacity: 1;
}

@media screen and (min-width: 768px) {
  .project--container {
    padding-top: 25vw;
    grid-template-columns: repeat(2, 1fr);
  }

  .privacy {
    align-items: flex-start !important;
    margin-top: 2rem;
    font-size: 0.9rem;
  }
}

@media screen and (min-width: 800px) {
  .project--container {
    grid-template-columns: repeat(3, 1fr);
  }
}

.project--card {
  max-width: 100%;
  height: 100%;
  cursor: pointer;
  transition: all 5s ease;
}

.project--img {
  object-fit: cover;
  width: 100%;
  min-height: 100%;
}

.project--img.trans:hover {
  transform: scale(1.05);
  transition: all 0.8s ease;
}

.project--head {
  top: 0;
  background-color: rgba(183, 210, 75, 0.6);
  height: 100%;
  width: 100%;
  text-transform: uppercase;
  color: var(--alt-white);
  transition: all 1s ease;
}

.project--card:hover {
  transform: scale(1.05);
  transition: all 0.6s ease;
}

.project--card:hover .project--head {
  display: none;
}

.project--title {
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  background-color: rgba(69, 82, 20, 0.9);
  font-size: 1rem;
  line-height: 40px;
  padding: 0.5rem;
}

.pro--summary {
  width: 100%;
}

.project--details {
  top: 0;
  margin: 0 auto;
  align-self: start;
}

.project--description a {
  color: var(--primary--03);
  font-weight: bold;
}

.project--link {
  margin-top: 2rem;
  background-color: #455214;
  color: var(--alt-white);
  text-align: center;
  padding: 0.5rem;
  transition: all 0.6s ease;
}

.project--link:hover {
  background-color: #91aa2b;
  color: var(--alt-white);
  font-weight: bold;
}

div.project--links > ul > li > a {
  color: var(--primary--03);
  transition: color 0.6s ease;
}

div.project--links > ul > li > a:hover {
  color: var(--primary--01);
}

#blog-section .about--content {
  width: 100%;
  padding-top: 5vh;
}

.blog--slider {
  max-width: 50%;
}

.blog {
  width: 90%;
}

.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 10px;
  background: var(--primary--03);
  transform-origin: 0%;
}

.desktop {
  display: none;
}

@media screen and (min-width: 462px) {
  .funnel--page--container {
    top: 27vh;
  }
}

@media screen and (min-width: 465px) {
  .motivation {
    background-position: 0 0, 0 15%;
  }

  .funnel--page--container {
    top: 30vh;
  }

  .exit {
    left: 10%;
  }

  .max--text-1 {
    width: 60%;
  }

  .hero--text {
    max-width: 90%;
  }

  .sub-text {
    font-size: 14px;
    line-height: 1.5;
  }

  .gallery {
    top: 5vh;
  }

  .climate-art {
    top: 90%;
  }

  .climate--title {
    max-width: 60%;
    margin-bottom: 1.5rem;
  }

  .sponsors--page {
    top: -20vh;
  }
}

@media screen and (min-width: 477px) {
  .funnel--page--container {
    top: 27vh;
  }
}

@media screen and (min-width: 576px) {
  .motivation {
    background-position: 0 0, 0 7%;
  }

  .exit {
    left: 17%;
  }

  .max--text-1 {
    width: 50%;
  }

  .hero--text {
    max-width: 80%;
  }

  .gallery {
    top: 8vh;
  }

  .climate-art {
    top: 93%;
  }

  .funnel--page--container {
    top: 28vh;
  }

  .sponsors--page {
    top: -15vh;
  }
}

@media screen and (min-width: 650px) {
  .funnel--page--container {
    top: 28vh;
  }

  .sponsors--page {
    top: -20vh;
  }

  .form {
    width: 80%;
    margin: 4% auto 2%;
    align-items: center;
  }

  .footer__top {
    display: flex;
    gap: 5%;
    align-items: center;
  }

  .footer--icon {
    position: relative;
    top: 19px;
  }
}

@media screen and (min-width: 768px) {
  /* Motivation & Hero */
  .motivation {
    top: -80vh;
  }

  .blog {
    width: 100%;
  }

  .mobile {
    display: none;
  }

  .privacy.desktop {
    display: flex;
  }

  .sub--hero--div {
    background-size: 80%, 35%;
    background-position: 10vh 10vh, right 0;
  }

  .climate-art {
    padding: 7% 8%;
  }

  .sub-text {
    font-size: 1rem;
    line-height: 1.8;
  }

  /* Features section */

  .feature--div {
    top: -30vh;
    padding: 30vh 0 65px 0;
  }

  .feature--container {
    flex-direction: row;
    gap: 0;
    margin: 1rem auto 0;
  }

  .feature--img {
    width: 80%;
  }

  div.feature--container.product.d-flex img.feature--img {
    height: 120px;
    margin: 3rem 0;
    object-fit: contain;
  }

  .feature--body {
    padding: 1rem 2rem;
  }

  .card-title {
    font-size: var(--size--24);
  }

  .feature--header {
    flex-direction: row;
  }

  .sub--theme {
    width: 35%;
    font-size: var(--size--32);
  }

  hr.hr--theme {
    margin-top: 0;
  }

  /* Funnel and Sponsor */

  .funnel--page--container {
    width: 80%;
    padding: 5%;
    top: 20vh;
  }

  .funnel--input {
    width: 90%;
    padding: 3%;
    border: none;
    font-size: 1rem;
  }

  .funnel--form {
    width: 90%;
  }

  ::placeholder {
    font-size: 1rem;
  }

  .funnel--btn {
    font-size: 1.25rem;
    padding: 2%;
    right: 11%;
    top: 45%;
  }

  .funnel--title {
    max-width: 40%;
    font-size: 1.5rem;
    text-align: center;
    padding: 1rem 0.5rem 0;
    line-height: 32px;
  }

  .funnel--description {
    margin: 1rem 0;
    font-size: 1rem;
  }

  .sponsors--page {
    top: -5vh;
  }

  /* Footer */
  .footer--container.main {
    grid-template-columns: 1fr 1fr;
    padding: 3%;
    background-color: #455214;
  }

  .footer__top {
    flex-direction: row;
    gap: 2rem;
  }

  .form {
    border: 1px solid #91aa2b;
    border-radius: 10px;
    padding: 1rem;
    height: max-content;
    width: 100%;
  }

  .input-div {
    width: 100%;
    gap: 0.5rem;
    align-items: center;
  }

  .text-input {
    height: 40px;
  }

  .contact__form--input {
    background: #ecf8c1;
    border: 1px solid #455214;
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    width: 100%;
    padding: 10px;
  }

  .contact__form--input::placeholder {
    color: #455214;
  }

  textarea {
    height: 100% !important;
    margin: 0.5rem 0;
  }

  .contact__form--btn {
    width: max-content;
    padding: 12px 1.5rem;
    border: none;
    background: #91aa2b;
    border-radius: 10px;
    color: #f2f7de;
    font-weight: 600;
    font-size: 2.5vh;
    height: 48px;
  }

  .footer__links {
    max-width: 300px;
    color: #fff;
    font-size: 14px;
    line-height: 20px;
    gap: 0.5rem;
  }

  .footer__links--top {
    gap: 0.5rem;
  }

  .footer--icon {
    width: 20px;
    height: 20px;
  }

  .footer--logo {
    max-width: 80%;
    width: 40%;
  }

  div.pro--summary {
    padding: 30vh 0 5% 5%;
    grid-template-columns: 2fr 1fr;
  }

  #blog-section .about--content {
    padding-top: 20vh;
  }
}

/* Media Queries */

@media screen and (min-width: 860px) {
  .motivation {
    top: -100vh;
  }

  .sub--hero--img {
    position: relative;
    top: -25vh;
    left: 0;
    width: 100%;
    object-fit: contain;
  }

  .sub--hero--div {
    background-size: 80%, 35%;
    background-position: 10vh 10vh, right -10vh;
  }

  .product--link {
    width: 310px;
    padding: 0;
  }

  .feature--container {
    gap: 1rem;
  }

  .card-title {
    font-size: 1.25vw;
  }
}

@media screen and (min-width: 1024px) {
  .sponsors--page {
    top: 0;
  }

  .funnel--page--container {
    top: 15vh;
  }

  .footer--container {
    grid-template-columns: 1fr 1fr;
  }

  .footer__top {
    flex-direction: row;
    align-items: start;
    justify-content: space-between;

    .footer--container.main {
      margin-top: 4rem;
    }
  }
}

@media screen and (min-width: 1320px) {
  .funnel--page--container {
    top: 17vh;
  }

  .funnel--title {
    max-width: 300px;
  }

  .funnel--btn {
    right: 10.7%;
    top: 45.1%;
  }
}
