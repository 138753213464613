.topSection {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;                                                                                  

  @media screen and (min-width: 768px) {
    background-image: url('../../../assets/backgrounds/vid_placeholder.webp');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    top: 0;
    margin-top: 0;
    height: 40vh;

    .topLink {
      color: #fff;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.topImage {
  position: relative;
  top: 10px;
  width: 120%;
  text-align: center;
  object-fit: cover;
  height: 300px;

  @media screen and (min-width: 768px) {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 30vh;
    top: 37vh;
    width: 50%;
    background-color: #fff;
    object-fit: cover;
    z-index: 1;
  }
}

.overlay {
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
  height: 300px;
  background: rgba(42, 49, 12, 0.8);
  z-index: 2;

  .topLinks {
    display: none;
  }

  @media screen and (min-width: 768px) {
    width: 50%;
    left: 50%;
    top: 37vh;
    height: 30vh;
    transform: translate(-50%, -50%);
  }
}

.topHeader {
  position: absolute;
  line-height: 1.1;
  margin-top: 3rem;
  font-size: 58px;
  z-index: 3;
  color: var(--white);
  font-family: var(--tiempos-fine);
  transform: translate(-50%, -50%);

  @media screen and (min-width: 768px) {
    font-size: 72px;
    margin-top: 0;
    top: 30vh;
    padding: 1rem;
    transform: translate(-50%, -50%);
  }
}